<template>
  <div class="top-for-sale">
    <span class="neon-text">FOR SALE</span>
  </div>

  <div class="cyber-container">
    <h1 class="neon-title flicker-effect">GENX.AI</h1>
    <p class="glow-text">🚀 This Domain is for Sale! 🚀</p>
    <button @click="showModal = true" class="cyber-button">Contact Us</button>
  </div>

  <!-- Contact Modal -->
  <div v-if="showModal" class="modal-overlay">
    <div class="modal">
      <button class="close-button" @click="showModal = false">✖</button>

      <h2 class="modal-title">Contact Us</h2>

      <form @submit.prevent="submitForm">
        <input type="email" v-model="email" placeholder="Your Email" required />
        <textarea v-model="message" placeholder="Your Message" required></textarea>
        <button type="submit" class="cyber-button">Send</button>
      </form>

      <p v-if="submitted" class="success-message">✅ Message sent successfully!</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
      email: "",
      message: "",
      submitted: false,
    };
  },
  methods: {
    async submitForm() {
      try {
        const response = await fetch("https://formspree.io/f/mldgwbyv", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email: this.email, message: this.message }),
        });

        if (response.ok) {
          this.submitted = true;
          this.email = "";
          this.message = "";

          // Hide success message after 3 seconds
          setTimeout(() => {
            this.submitted = false;
            this.showModal = false;
          }, 3000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap");

body {
  background: #000;
  color: #0ff;
  font-family: "Orbitron", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  overflow: hidden;
}
.glow-text {
  font-size: 1.5rem;
  font-weight: 700;
  text-shadow: 0 0 5px #ff007f, 0 0 15px #ff007f;
  letter-spacing: 1.5px;
  margin-bottom: 50px; /* Adjust this */
}

/* 🔥 FOR SALE - Floating Neon Text */
.top-for-sale {
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translateX(-50%);
}

.neon-text {
  font-size: 2.5rem;
  font-weight: 800;
  color: #ff007f;
  text-shadow: 0 0 5px #ff007f, 0 0 10px #ff007f;
  letter-spacing: 2px;
  margin-bottom: 5px;
}

/* 🌟 MAIN CONTENT */
.cyber-container {
  text-align: center;
  animation: fadeIn 1.5s ease-in-out;
  margin-top: 30px; /* Reduced space */
}

.neon-title {
  font-size: 4rem;
  font-weight: 800;
  color: #0ff;
  text-shadow: 0 0 5px #0ff, 0 0 15px #0ff;
  letter-spacing: 2px;
  display: inline-block;
  padding: 10px 30px;
  border: 4px solid #0ff; /* Tubelight effect */
  animation: flicker-light 2s linear;
}

/* Tubelight Flickering Effect */
@keyframes flicker-light {
  0% { opacity: 0; text-shadow: none; border-color: transparent; }
  20% { opacity: 0.3; text-shadow: 0 0 5px #0ff; }
  40% { opacity: 1; text-shadow: 0 0 10px #0ff, 0 0 20px #0ff; }
  60% { opacity: 0.5; text-shadow: 0 0 5px #0ff; border-color: #0ff; }
  80% { opacity: 1; text-shadow: 0 0 10px #0ff, 0 0 20px #0ff; }
  100% { opacity: 1; text-shadow: 0 0 15px #0ff, 0 0 30px #0ff; border-color: #0ff; }
}

/* 🔥 MODAL STYLES */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: rgba(0, 0, 0, 0.9);
  border: 2px solid #0ff;
  padding: 20px;
  text-align: center;
  box-shadow: 0 0 20px #0ff;
  position: relative;
  width: 400px;
}

/* Modal Title */
.modal-title {
  font-size: 2rem;
  color: #0ff;
  text-shadow: 0 0 5px #0ff, 0 0 10px #0ff;
  margin-bottom: 20px;
}

/* Form Inputs */
input, textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 2px solid #0ff;
  background: rgba(0, 0, 0, 0.8);
  color: #0ff;
  font-size: 1rem;
  font-family: "Orbitron", sans-serif;
  text-shadow: 0 0 5px #0ff;
}

input::placeholder, textarea::placeholder {
  color: rgba(0, 255, 255, 0.5);
}

textarea {
  height: 100px;
  resize: none;
}

/* Close Button */
.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 1.5rem;
  color: #ff007f;
  cursor: pointer;
  border: none;
  background: none;
  text-shadow: 0 0 5px #ff007f, 0 0 10px #ff007f;
}

.close-button:hover {
  transform: scale(1.2);
}

/* Success Message */
.success-message {
  color: #0f0;
  font-size: 1rem;
  margin-top: 10px;
}

/* Cyberpunk Button */
.cyber-button {
  display: inline-block;
  padding: 15px 30px;
  font-size: 1.5rem;
  color: #000;
  background: #0ff;
  border: 3px solid #0ff;
  border-radius: 6px;
  text-decoration: none;
  box-shadow: 0 0 10px #0ff, 0 0 40px #0ff;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.cyber-button:hover {
  background: #ff007f;
  border-color: #ff007f;
  box-shadow: 0 0 10px #ff007f, 0 0 40px #ff007f;
  transform: scale(1.1);
}

@media screen and (max-width: 768px) {
  .top-for-sale {
    font-size: 1.5rem; /* Reduce size */
    letter-spacing: 1px; /* Reduce spacing */
    white-space: nowrap; /* Prevent line break */
  }

  .neon-title {
    font-size: 2rem; /* Reduce GENX.AI size */
    padding: 5px 20px;
    border-width: 3px;
  }

  .glow-text {
    font-size: 1rem; /* Adjust size */
    margin-bottom: 20px;
    
    /* Add padding to create space around the text */
    padding: 5px 20px; /* Top/Bottom 5px, Left/Right 20px */

    /* Add a slight border on sides */
    border-left: 2px solid #ff007f;
    border-right: 2px solid #ff007f;
  }


  .cyber-button {
    font-size: 1.2rem;
    padding: 12px 25px;
  }

  /* Modal Adjustments */
  .modal {
    width: 90%; /* Make modal take more space */
    padding: 15px;
  }

  .modal-title {
    font-size: 1.5rem;
  }

  input, textarea {
    font-size: 0.9rem;
    padding: 8px;
  }

  .close-button {
    font-size: 1.2rem;
    top: 5px;
    right: 10px;
  }
}

@media screen and (max-width: 1024px) {
  .top-for-sale {
    font-size: 2rem;
    top: 15px;
  }

  .neon-title {
    font-size: 3.5rem;
    padding: 8px 25px;
  }

  .glow-text {
    font-size: 1.4rem;
    margin-bottom: 40px;
  }

  .cyber-button {
    font-size: 1.3rem;
    padding: 14px 28px;
  }

  /* Modal Adjustments */
  .modal {
    width: 80%;
    padding: 20px;
  }

  .modal-title {
    font-size: 1.8rem;
  }

  input, textarea {
    font-size: 1rem;
    padding: 10px;
  }

  .close-button {
    font-size: 1.5rem;
  }
}

</style>
